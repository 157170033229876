<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-button size="small" type="primary" @click="add" v-if="!disabled">添加商超</el-button>
      </el-col>
    </el-row>
    <el-form label-width="125px">
      <el-row v-for="(item, index) in value" :key="index">
        <el-col :span="8">
          <el-form-item label="适用商超" required>
            <el-select v-model="item.directId" :disabled="disabled" :remote-method="remoteMethod" @change="getChange" @focus="remoteMethod" filterable remote placeholder="请选择">
              <el-option
                v-for="item in productOptions"
                :key="item.id"
                :label="item.directName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="价格容差" required>
            <el-row>
              <el-col :span="8">
                <el-select v-model="item.auditRule" :disabled="disabled" placeholder="请选择">
                  <el-option
                    v-for="item in priceOptions"
                    :key="item.dictCode"
                    :label="item.dictValue"
                    :value="item.dictCode">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="10">
                <el-input-number v-model="item.differencePrice" :disabled="disabled"></el-input-number>
              </el-col>
            </el-row>
            <p class="tips">此价格容差作用于稽核结果含税金额差异</p>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import request from '@/found/utils/request';

export default {
  props: {
    value: Array,
    disabled: Boolean,
  },
  data() {
    return {
      productOptions: [],
      priceOptions: [],
    };
  },
  created() {
    this.getPriceDic();
    this.remoteMethod();
    this.$emit('input', this.value);
  },
  methods: {
    // 获取价格容差数据字典
    getPriceDic() {
      request.post('/mdm/mdmdictdata/batchDictSelect', ['kms_audit_rule'], { headers: { functionCode: 'financial_audit_template_list' } }).then((res) => {
        this.priceOptions = res.result.kms_audit_rule || [];
      });
    },
    // 远程搜索
    remoteMethod(val) {
      const data = {
        pageNum: 1,
        pageSize: 50,
        directName: (val && val.type === 'focus') ? '' : val,
      };
      request.post('/kms/kmsdirectsystem/list', data, { headers: { functionCode: 'financial_audit_template_list' } }).then((res) => {
        this.productOptions = res.result.data;
      });
    },
    // 添加商超
    add() {
      this.value.push({
        directId: '',
        auditRule: '',
        differencePrice: '',
        directName: '',
      });
      this.$emit('input', this.value);
    },
    // 获取商超名称
    getChange(e) {
      const selectData = this.productOptions.find((val) => val.id === e);
      const index = this.value.findIndex((val) => val.directId === e);
      this.value[index].directName = selectData.directName;
      this.$emit('input', this.value);
    },
  },
};
</script>
<style lang="less" scoped>
  /deep/ .el-input-number{
    margin-left: 20px;
  }
  .tips::before{
    content: '*';
    color: red;
    display: inline-block;
    padding-right: 5px;
  }
</style>
