var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.add },
                    },
                    [_vm._v("添加商超")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form",
        { attrs: { "label-width": "125px" } },
        _vm._l(_vm.value, function (item, index) {
          return _c(
            "el-row",
            { key: index },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "适用商超", required: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            "remote-method": _vm.remoteMethod,
                            filterable: "",
                            remote: "",
                            placeholder: "请选择",
                          },
                          on: {
                            change: _vm.getChange,
                            focus: _vm.remoteMethod,
                          },
                          model: {
                            value: item.directId,
                            callback: function ($$v) {
                              _vm.$set(item, "directId", $$v)
                            },
                            expression: "item.directId",
                          },
                        },
                        _vm._l(_vm.productOptions, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.directName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "价格容差", required: "" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: item.auditRule,
                                    callback: function ($$v) {
                                      _vm.$set(item, "auditRule", $$v)
                                    },
                                    expression: "item.auditRule",
                                  },
                                },
                                _vm._l(_vm.priceOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.dictCode,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.dictCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c("el-input-number", {
                                attrs: { disabled: _vm.disabled },
                                model: {
                                  value: item.differencePrice,
                                  callback: function ($$v) {
                                    _vm.$set(item, "differencePrice", $$v)
                                  },
                                  expression: "item.differencePrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("p", { staticClass: "tips" }, [
                        _vm._v("此价格容差作用于稽核结果含税金额差异"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }