<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import formAdd from './components/formAdd.vue';

formCreate.component('directVos', formAdd);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const rule = await this.getFormRule('financial_audit_template_form');
    this.reload(rule);
    if (this.formConfig.id) {
      request.post('/kms/kmsaudittemplate/query', { id: this.formConfig.id }, { headers: { functionCode: 'financial_audit_template_list' } }).then((res) => {
        this.setValue({
          ...res.result,
        });
        this.row = res.result;
      });
    }
    if (this.formConfig.type === 'view') {
      this.buttons.submit = false;
    } else {
      this.buttons.submit = true;
    }
  },

  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'conditionList') {
        // 选择匹配条件
        item.restful = '/mdm/mdmdictdata/list';
        item.headers = { functionCode: 'financial_audit_template_list' };
        item.restfulParams = {
          dictTypeCode: 'kms_audit_condition',
        };
        item.optionsKey = { label: 'dictValue', value: 'dictCode' };
        item.refresh = true;
      } else if (item.field === 'directVos') {
        // 添加商超
        item.props = {
          ...item.props,
          disabled: true,
        };
        item.value = [{
          directId: '',
          auditRule: '',
          differencePrice: '',
          directName: '',
        }];
      }
      return item;
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      const param = {
        ...this.row,
        ...formData,
      };
      request.post(this.formConfig.id ? ('/kms/kmsaudittemplate/update') : ('/kms/kmsaudittemplate/save'), param, { headers: { functionCode: 'financial_audit_template_list' } }).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });

          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
